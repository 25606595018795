import type { PageProps } from "gatsby";
import * as React from "react";
import { graphql, navigate } from "gatsby";
import {
  parseLink,
  linkToString,
} from "gatsby-theme-cookierun-kingdom/src/link";
import Seo from "../components/Seo";

type IndexPageProps = PageProps<GatsbyTypes.IndexPageQuery>;

const IndexPage: React.FC<IndexPageProps> = ({ data, location }) => {
  React.useEffect(() => {
    const availableLanguages = data.allEventTarget.nodes.map(
      (node) => node.language
    );
    const [navigatorLanguage] = window.navigator.language.split("-");
    const targetLanguage =
      availableLanguages.find((lang) => lang.startsWith(navigatorLanguage)) ||
      "en";

    const current = new URL(window.location.href);
    current.pathname = `/${targetLanguage}/`;

    const replaceTo = parseLink(current.href, window.location.origin);
    void navigate(linkToString(replaceTo), { replace: true });
  }, [data]);

  const koLokaliseOpengraphData = data.allLokaliseTranslationMessages.nodes.filter((v, i)=>v.language === 'ko')[0].messages;

  return (
    <Seo
      language={'ko'}
      title={koLokaliseOpengraphData?.IndexPage_OpenGraph_title?.text ?? '쿠키런: 킹덤'}
      description={koLokaliseOpengraphData?.IndexPage_OpenGraph_description?.text ?? '처음 ‘맛’나는 소셜 RPG'}
      location={location}
    />
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    allLokaliseTranslationMessages {
      nodes {
        messages {
          IndexPage_OpenGraph_title {
            text
          }
          IndexPage_OpenGraph_description {
            text
          }
        }
        language
      }
    }
    allEventTarget {
      nodes {
        language
      }
    }
  }
`;
